<template>
  <div class="mt-4">
    <b-card class="text-black">
      <b-row
        class="mt-2 mb-2"
        style="border: 1px solid #ccc; border-radius: 5px; padding: 10px"
      >
        <b-col md="3">
          <b-form-group label="Select hospital">
            <v-select
              v-model="selectedHospital"
              :options="hospitalList"
              class="vSelectStyle bg-white"
              placeholder="Select hospital"
              label="option"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button
            variant="primary"
            pill
            style="margin-top: 25px"
            @click="searchSummaryViaSearchButton"
          >
            <feather-icon icon="SearchIcon" class="mr-50" />
            Search
          </b-button>
        </b-col>
      </b-row>
      <div class="feedback-container">
        <img src="@/assets/images/logo/CNE.png" class="bgImg" alt="" />
        <p><u>S&VMFC</u></p>

        <p v-if="selectedHospital">{{ selectedHospital.option }}</p>
        <p v-else>MILITARY HOSPITALS</p>
        <p class="mb-3 summ">FEEDBACK SUMMARY</p>
        <validation-observer ref="feedbackFormValidation">
          <b-form @submit.prevent>
            <!-- <b-form-row class="mb-2">
                  <b-form-group label-for="paNo" label-cols="3">
                    <template #label>
                      PA No <span class="text-danger">*</span>
                    </template>
                    <b-col md="9">
                      <validation-provider
                        #default="{ errors }"
                        name="PA No"
                        rules="required|integer"
                      >
                        <b-form-input
                          id="paNo"
                          v-model="paNo"
                          :state="errors.length > 0 ? false : null"
                          placeholder="PA No"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-form-group>
                  <b-form-group label-for="rank" label-cols="3">
                    <template #label>
                      Rank <span class="text-danger">*</span>
                    </template>
                    <b-col md="9">
                      <validation-provider
                        #default="{ errors }"
                        name="rank"
                        rules="required|alpha"
                      >
                        <b-form-input
                          id="rank"
                          v-model="rank"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Rank"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-form-group>
                  <b-form-group label-for="name" label-cols="3">
                    <template #label>
                      Name <span class="text-danger">*</span>
                    </template>
                    <b-col md="9">
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-form-group>
                </b-form-row> -->

            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>1. Communication:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="communicationRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>2. Transportation and Reception Arrangements:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="transportRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>3. Wheel Chair / Stretcher Services:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="stetcherRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>4. Facilitation at S&VMFC:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="facilitationRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>5. Lab Tests / X Ray / Medicines:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="labRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>6. Fixing of Appointment with Splt:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="appointmentRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>7. Nursing Assistant Accompanied:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="nursingRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>8. Reports Collection:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="reportRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>9. Transportation of Return:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="transportReturnRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>10. Conduct by Doctor with Self and Family:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="drConductRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="feedback-content mb-50">
              <b-row>
                <b-col md="8">
                  <div class="feedback-text">
                    <p>11. Conduct / Dress of S&VMFC Staff:</p>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="radio-buttons">
                    <star-rating
                      :star-size="25"
                      :increment="0.01"
                      :read-only="true"
                      v-model="staffConductRating"
                      class="align-stars"
                    ></star-rating>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- <div class="feedback-content mb-2">
                  <b-row>
                    <b-col md="12">
                      <div class="feedback-text">
                        <p class="mb-1">12. Any other Observation / Suggestion:</p>
                      </div>
                    </b-col>
                    <b-col md="11">
                      <div class="radio-buttons">
                        <b-textarea type="text" v-model="suggestion"></b-textarea>
                      </div>
                    </b-col>
                  </b-row>
                </div> -->

            <!-- <b-form-group class="text-right">
                  <b-button
                    type="submit"
                    variant="primary"
                    pill
                    @click="validationForm"
                  >
                    Submit Feedback
                  </b-button>
                </b-form-group> -->
          </b-form>
        </validation-observer>
      </div>
      <b-button
        variant="primary"
        pill
        @click="printFeedbackSection"
      >
        Print
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import StarRating from "vue-star-rating";
export default {
  components: {
    StarRating,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: "",
      hospitalList: [],
      selectedHospital: null,
      communicationRating: null,
      transportRating: null,
      staffConductRating: null,
      drConductRating: null,
      transportReturnRating: null,
      reportRating: null,
      nursingRating: null,
      appointmentRating: null,
      labRating: null,
      facilitationRating: null,
      stetcherRating: null,
    };
  },
  async mounted() {
    await this.getHospitals();
    await this.fetchSummary();
    const userData = await this.getLoggedInUser;
    this.user = userData.name;
  },
  methods: {
    ...mapActions({
      fetchFeedbackSummary: "appData/fetchFeedbackSummary",
      hospitals: "appData/hospitals",
    }),
    async getHospitals() {
      const res = await this.hospitals();
      const hospitalsData = res.data.results;
      this.hospitalList = hospitalsData.map((hosp) => ({
        value: hosp.id,
        option: hosp.name,
      }));
    },

    printFeedbackSection() {
      // Create a new HTML document for printing
      const printWindow = window.open("", "", "");
      const printableContent = document.querySelector(".feedback-container");

      // Check if the printable content exists
      if (printableContent) {
        // Clone the content into the new window
        const clonedContent = printableContent.cloneNode(true);
        printWindow.document.body.appendChild(clonedContent);

        // Add some additional styling if needed
        const styles = `
          <style>
              .text-black {
  color: black !important;
}

.feedback-container {
  font-size: 17px;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  
}
 .feedback-content {
      page-break-inside: avoid;
    }
    .align-stars {
  margin-left: 500px;
  align-items: center;
  padding-bottom:-50px !important;
}
.radio-buttons {
  display: flex;
  margin-top: -45px;
  margin-left: 20px;
}
.summ{
  margin-bottom:20px;
}
.sr-only{
  display: none !important;
}
.bgImg{
  display : block;
  margin-top: 35px;
}


          </style>
        `;
        printWindow.document.head.insertAdjacentHTML("beforeend", styles);

        // Wait for the cloned content to be fully loaded
        clonedContent.onload = function () {
          // Trigger the print dialog for the new window
          printWindow.print();
          // Close the new window after printing
          printWindow.close();
        };
      } else {
        // Handle the case where the printable content doesn't exist
        console.error("Printable content not found.");
      }
    },

    async searchSummary() {
      await this.searchSummaryViaSearchButton();
    },
    async searchSummaryViaSearchButton() {
      let payload = {};
      if (this.selectedHospital !== null) {
        payload["hospital"] = this.selectedHospital.value;

        await this.fetchSummary(payload);
      } else {
        await this.fetchSummary(payload);
      }
    },

    async fetchSummary(searchParams = {}) {
      try {
        const res = await this.fetchFeedbackSummary(searchParams);
        const summary = res.data.average_ratings;
        this.communicationRating = summary.communication;
        this.transportRating = summary.transportation_reception;
        this.staffConductRating = summary.conduct_dress_SVMFC_staff;
        this.drConductRating = summary.conduct_doc_self_family;
        this.transportReturnRating = summary.transportation_on_return;
        this.reportRating = summary.reports_collection;
        this.nursingRating = summary.nursing_accompanied;
        this.appointmentRating = summary.fixing_appointment;
        this.labRating = summary.lab_xray_medicine;
        this.facilitationRating = summary.facilitation_SVMFC;
        this.stetcherRating = summary.wheel_chair_stretcher;
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },
};
</script>

<style scoped>
.text-black {
  color: black !important;
}
label {
  font-size: 110%;
  font-weight: 400;
  color: #000;
}
::placeholder {
  color: black;
}
.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feedback-point-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-buttons {
  display: flex;
  margin-top: -10px;
  margin-left: 20px;
}
.align-stars {
  margin-left: 42px;
}

p {
  font-weight: 800;
}
.bgImg {
  display: none;
}
</style>
